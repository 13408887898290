@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans:300);


/* html {
  position: relative;
  min-height: 100%;
}  */
.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: fit-content;
}


@media (max-width: 567em) {

    .footer {
      height: fit-content;
    }
}

/* Sticky footer style and color
-------------------------------------------------- */
footer {
  padding-top: 30px;
  background-color:rgb(19, 19, 163);
  color: #bbb;
}

footer a {
  color: #999;
  text-decoration:none;
}

footer a:hover, footer a:focus {
  color: #aaa;
  text-decoration:none;
  border-bottom:1px dotted #999;
}

footer .form-control {
    background-color: #1f2022;
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
    border: none;
    resize: none;
    color: white;
    padding: 0.7em 1em;
}

footer .btn{
    color: white;
    border: thik;
    border-color: black;
    border-radius: 10px;
    justify-content: center;
}
footer .form-group{
    padding: 5px 0;
}

