.navbar{
    display:flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgb(255, 167, 5);
    padding:10px
}

.navbar-links{
    color:rgb(19, 19, 163); 
    font-size:24px;
    font-family: "Times New Roman", Times, serif;
    text-decoration: none;
    font-weight: 800;
}
.login-btn{
    background-color: blue;
    color: white;
    padding: 10px;
    border-radius: 5px;
}



@media screen and (max-width: 874px) {
    .navbar-links{
        font-size:15px;  
        font-weight: 400;
    }

  }

 