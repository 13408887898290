.form-item{
    display: flex;
    align-items: center;
    
    justify-content: flex-start;
}

.form{
    background-color: rgb(255, 167, 5);
    padding:20px;
    border-radius: 20px;
    border:1px solid rgb(255, 167, 5);
    width: fit-content;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
}
.form label{
    width:120px;
    color:blue;
    font-size: 26px;
margin-right: 20px;
}
.btn-form{
    background-color: blue;
    color: white;
    border-radius:5px ;
    padding: 20px;
    font-size: 26px;
}
.input-form{
    padding:10px;
    width:250px;
    background-color: beige;
    border-radius: 5px;
    margin:5px
}

#container{
    width:100%;
    height: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: blue;
}