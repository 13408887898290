.header{
    /* width:100vw; */
    text-align:center;
    background-color:rgb(19, 19, 163); 
    padding:8px;
    justify-content: space-around;
    display: flex;
    align-items: center;
}
.t-st{
    text-align: center;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;

}

.title{
    color:white;
    font-size:50px;
    font-family: "Times New Roman", Times, serif;

}
.subtitle{
    color:white;
    font-size:30px;
    font-family: "Times New Roman", Times, serif;
}

.logo{
    width: 150px;
    height: auto;

}

@media screen and (max-width: 874px) {
    .title{
        font-size: 30px;
    width: 120px;

    }
    .subtitle{
    font-size:25px;

    }
    .logo{
        width: 130px;
    }

  }

  @media screen and (max-width: 570px) {
    .title{
        font-size: 15px;
    /* width: 100px; */

    }
    .subtitle{
        font-size:10px;
    
        }
        .logo{
            width: 50px;
        }
  }