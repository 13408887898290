.container-aboutus{
width:80vw;
background-color: goldenrod;
margin:10px;
text-align: center;
border-radius: 15px;
box-shadow: 10px 20px 30px goldenrod;
padding: 30px 20px;
}
.homepage{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.aboutus-title{
    color:rgb(19, 19, 163); 
    font-size:4rem;
    font-family: "Times New Roman", Times, serif;
    text-decoration: underline;
    font-weight: 800;

    /* margin-top:20px; */
    
}
.aboutus-content{
    text-align: center;
    color:aliceblue;
    font-size:30px
    
}   
.CardTopFour-card{
    width:17rem;
    border:2px solid gray;
    box-shadow: 10px 20px 30px gray;
    border-radius: 5px;
    margin:10px
}

.CardTopFour{
    padding:10px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    width:90%;
}

.comment{
    width:auto;
    overflow:hidden;
    height: 100px;
    /* line-height:50px; */
    /* color: red; */
  }
  .CardTopFour-card-img{
    height: 250px;
    width: 100%;
  }
  @media screen and (max-width: 874px) {
    .aboutus-title{
        font-size: 1.5rem;
    }
    .aboutus-content{
        font-size: 10px;
    }
    .CardTopFour-card-img{
        height: 150px;
    }
    .CardTopFour-card{
        width:12rem;
        border:2px solid gray;
        box-shadow: 10px 20px 30px gray;
        border-radius: 4px;
        margin:5px
    }
    .comment{
        height: 70px;
    }

  }